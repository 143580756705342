
.refDiv {
  width: 100%
}

.referenceList {
  list-style-type: none;
  margin: auto;
  background-color: #ffffffbb;
  font-family: Arial, sans-serif;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  width: 75%
}

.referenceList h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.referenceList li {
  margin-bottom: 10px;
}

.referenceList li a {
  color: #333;
  text-decoration: none;
  font-size: 18px;
}

.referenceList li a:hover {
  text-decoration: underline;
}