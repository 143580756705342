h1 {
  font-family: "Fira code", "Fira Mono", monospace;
  font-size: 50px;
  text-align: center;
  margin-top: 20px;

}

.introduction {
  grid-gap: 10px;
  width: 80%;
  display: grid;
  grid-template-columns: auto;
  margin-bottom: 20px;
}

.beginButton {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  margin: 10px;
  text-align: center;
  text-decoration: none;
  font-family: "Fira code", "Fira Mono", monospace;
  display: inline-block;
  font-size: 20px;
  border-radius: 12px;
  cursor: pointer;
  transition-duration: 0.4s;
  width: 75%;
}

.beginButtonerror {
  background-color: rgb(216, 31, 24);
  border: none;
  color: white;
  padding: 15px 32px;
  margin: 10px;
  text-align: center;
  text-decoration: none;
  font-family: "Fira code", "Fira Mono", monospace;
  display: inline-block;
  font-size: 20px;
  border-radius: 12px;
  cursor: pointer;
  transition-duration: 0.4s;
  width: 75%;
}