/* PrismJS 1.29.0
https://prismjs.com/download.html#themes=prism-dark */
code[class*=language-],
pre[class*=language-] {
  color: #fff;
  background: 0 0;
  text-shadow: 0 -.1em .2em #000;
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  font-size: 1em;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none
}

@media print {

  code[class*=language-],
  pre[class*=language-] {
    text-shadow: none
  }
}

:not(pre)>code[class*=language-],
pre[class*=language-] {
  background: #4c3f33
}

pre[class*=language-] {
  padding: 1em;
  margin: .5em 0;
  overflow: auto;
  border: .3em solid #7a6651;
  border-radius: .5em;
  box-shadow: 1px 1px .5em #000 inset
}

:not(pre)>code[class*=language-] {
  padding: .15em .2em .05em;
  border-radius: .3em;
  border: .13em solid #7a6651;
  box-shadow: 1px 1px .3em -.1em #000 inset;
  white-space: normal
}

.token.cdata,
.token.comment,
.token.doctype,
.token.prolog {
  color: #997f66
}

.token.punctuation {
  opacity: .7
}

.token.namespace {
  opacity: .7
}

.token.boolean,
.token.constant,
.token.number,
.token.property,
.token.symbol,
.token.tag {
  color: #cdd193
}

.token.builtin {
  color: #d1939e
}

.token.attr-name,
.token.char,
.token.inserted,
.token.selector,
.token.string {
  color: #51d6e0
}

.language-css .token.string,
.style .token.string,
.token.entity,
.token.operator,
.token.url,
.token.variable {
  color: #f4b73d
}

.token.atrule,
.token.attr-value,
.token.keyword {
  color: #d1b893
}

.token.important,
.token.regex {
  color: #e90
}

.token.bold,
.token.important {
  font-weight: 700
}

.token.italic {
  font-style: italic
}

.token.entity {
  cursor: help
}

.token.deleted {
  color: red
}