.grid-container {
  grid-gap: 10px;
  width: 90%;
  display: grid;
  grid-template-columns: 50% 50%;
  margin-bottom: 20px;
}

h2 {
  font-family: "Fira code", "Fira Mono", monospace;
  font-size: 30px;
  font-variant-ligatures: common-ligatures;
  margin-bottom: 20px;
}

.title {
  font-family: "Fira code", "Fira Mono", monospace;
  font-size: 30px;
  font-variant-ligatures: common-ligatures;
  margin-bottom: 20px;
  float: left;
}

.submitButton {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  margin: 10px;
  text-align: center;
  text-decoration: none;
  font-family: "Fira code", "Fira Mono", monospace;
  display: inline-block;
  font-size: 20px;
  border-radius: 12px;
  cursor: pointer;
  transition-duration: 0.4s;
  width: 75%;
}

.nextButton {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  margin: 10px;
  text-align: center;
  text-decoration: none;
  font-family: "Fira code", "Fira Mono", monospace;
  display: inline-block;
  font-size: 20px;
  border-radius: 12px;
  cursor: pointer;
  transition-duration: 0.4s;
  width: 50%;
}

.results {
  margin-top: 10px;
  width: 75%;
  background-color: rgb(138, 36, 36);
  border-radius: 10px;
  transition: height 1s linear;
  padding: 20px;
}

.results li {
  color: white;
  font-size: 30px;
  font-family: "Fira code", "Fira Mono", monospace;
  font-size: 20px;
  font-variant-ligatures: common-ligatures;
  margin-bottom: 20px;
  list-style-type: number;
}

.results_passed {
  margin-top: 10px;
  width: 75%;
  background-color: rgb(44, 150, 23);
  border-radius: 10px;
  font-family: "Fira code", "Fira Mono", monospace;
  font-size: 20px;
  font-variant-ligatures: common-ligatures;
  padding: 20px;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.container_editor_area {
  max-height: 400px;
  overflow: auto;
  margin: 1.67em 0;
  padding-left: 3em;
}

.container__editor {
  font-family: "Fira code", "Fira Mono", monospace;
  font-size: 20px;
  font-variant-ligatures: common-ligatures;
  background-color: #3b3b3b;
  border-radius: 10px;
  counter-reset: line;
}

.container_editor_line_number:before {
  position: absolute;
  text-align: left;
  left: 1%;
  opacity: .3;
  counter-increment: line;
  content: counter(line);
}